'use strict';

import CnSmartFtp from './cn-smart-ftp';
import CnSmartFtpLite from './cn-smart-ftp-lite';
import CnSmartForm from './cn-smart-form';
import CnSmartDetail from './cn-smart-detail';
import CnSmartPrimarySub from './cn-smart-primarySub';
import CnGloryFtp from './cn-glory-ftp';
import CnGloryForm from './cn-glory-form';
import CnGloryDrtail from './cn-glory-detail';

// 通过arr控制输出的顺序
var bundle = [
  CnSmartFtp,
  CnSmartForm,
  CnSmartDetail,
  CnSmartFtpLite,
  CnSmartPrimarySub,
  CnGloryFtp,
  CnGloryForm,
  CnGloryDrtail
];

export default bundle;
export {
  CnSmartFtp,
  CnSmartForm,
  CnSmartDetail,
  CnSmartFtpLite,
  CnSmartPrimarySub,
  CnGloryFtp,
  CnGloryForm,
  CnGloryDrtail
};
